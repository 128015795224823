<template>
    <div class="row">
      <div class="col-md-12">
        <div class="row py-3 px-4" id="filter-card">
          <div class="col-lg-12 col-xl-12 px-0">
            <div class="row justify-content-start items-baseline w-100 mb-2">
          <span
              @click="$router.go(-1)"
              class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2">
            <i class="fa fa-chevron-left"></i>
          </span>
              <h3 class="d-inline-block" id="page-title">{{ $t('general.product_model').toUpperCase() }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <dashboard-box :back="true" class="px-5 py-4">
          <template v-slot:preview>
            <div class="w-100 row mb-7" v-if="itemProxy">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <custom-multi-select
                    :title="`${$t('product_model.company')}`"
                    :disabled="id ? true : false"
                    :model.sync="itemProxy.company_id"
                    :not-list="true"
                    :max="1"
                    name="company_id"
                    :options="companies"
                ></custom-multi-select>
              </div>
  
              <div class="col-12 row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" v-for="(translation, index) in itemProxy.translations" :key="index">
                  <text-input
                      :title="`${$t('general.name')} (${getLanguageById(translation.language_id).name})`"
                      :model.sync="translation.name"
                      :not-list="true"
                      :max="1"
                  ></text-input>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <button-with-icon
                  class="mr-3 danger-border-1px"
                  @onClick="$router.go(-1)"
                  :text="$t('general.cancel')"
                  size="lg"
                  :icon-name="null"
              ></button-with-icon>
              <button-with-icon
                  @onClick="createOrUpdate"
                  :text="$t(id != null ? 'general.save' : 'general.create')"
                  size="lg"
                  :icon-name="null"
                  :disabled="!isValidToSubmit"
              ></button-with-icon>
            </div>
          </template>
        </dashboard-box>
      </div>
    </div>
  </template>
  
  <script>
  import DashboardBox from "@/assets/components/DashboardBox";
  import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
  
  import baseModule, {
    BASE_URL,
    ERROR,
    LOADING,
    MODULE_NAME,
    SUCCESS,
    ITEM,
    SET_ITEM,
    GET_ITEM,
    UPDATE_ITEM,
    CREATE_ITEM
  } from "@/core/services/store/product-model/productModel.module";
  
  import companyModule, {
    BASE_URL as COMPANY_BASE_URL,
    ITEMS as COMPANY_ITEMS,
    GET_ITEMS,
    MODULE_NAME as MODULE_NAME_COMPANY
  
  } from "@/core/services/store/company/company.module";
  
  import TextInput from "@/assets/components/inputs/TextInput";
  import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
  import store from "@/core/services/store";
  import router from "@/router";
  import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
  
  const _MODULE_NAME = MODULE_NAME;
  const _MODULE_NAME_COMPANY = MODULE_NAME_COMPANY;
  
  export default {
    name: "ProductModelCreateEdit",
    components: {
      ButtonWithIcon,
      TextInput,
      DashboardBox,
      CustomMultiSelect
    },
    beforeCreate() {
      function registerStoreModule(moduleName, storeModule) {
        if (!(store && store.state && store.state[moduleName])) {
          store.registerModule(moduleName, storeModule)
        }
      }
      registerStoreModule(_MODULE_NAME, baseModule);
      registerStoreModule(_MODULE_NAME_COMPANY, companyModule);
    },
    data() {
      return {
        id: this.$route.params.id,
        indexRouteName: 'management.product-model.index',
        tempItem: {
          translations: []
        }
      };
    },
    computed: {
      error() {
        return store.getters[_MODULE_NAME + '/' + ERROR];
      },
      loading() {
        return store.getters[_MODULE_NAME + '/' + LOADING];
      },
      success() {
        return store.getters[_MODULE_NAME + '/' + SUCCESS];
      },
      item() {
        return store.getters[_MODULE_NAME + '/' + ITEM];
      },
      isValidToSubmit() {
        if(this.itemProxy === null) return false;
        let formatted =  this.itemProxy;
        if(Number(formatted.company_id) === 0) return false;
        for(let i = 0; i < formatted.translations.length; i++){
          const current = formatted.translations[i].name;
          if(current === '' || current === null) return false;
        }
        return true;
      },
      itemProxy: {
        get() {
          if(this.item == null) return null;
          return this.item;
        },
        set(value){
          this.setItem(value);
        }
      },
      companies() {
        let items = store.getters[_MODULE_NAME_COMPANY + '/' + COMPANY_ITEMS];
  
        if (items) {
          return this.convertArrayToObjectByKey(items, 'id', 'name');
        }
  
        return [];
      },
    },
    methods: {
      createOrUpdate() {
        let message = this.id == null ? this.$t('general.successfully_created') : this.$t('general.successfully_updated');
        let payload = {
          url: this.id == null ? BASE_URL : BASE_URL + '/' + this.id,
          id: this.id,
          contents: this.itemProxy
        }
        this.$store.dispatch(this.id == null ? _MODULE_NAME + '/'+ CREATE_ITEM : _MODULE_NAME + '/' + UPDATE_ITEM, payload);
      },
      getCompanies() {
        this.$store.dispatch(_MODULE_NAME_COMPANY + '/' + GET_ITEMS, {
          url: COMPANY_BASE_URL,
          showLoading: false,
        });
      },
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: this.$t("general.dashboard"), route: "dashboard"},
        {title: this.$t("general.product_model"), route: this.indexRouteName},
        {title: this.$t(this.id ? "general.product_module_edit" : "general.product_module_create")},
      ]);
  
      if (_.isEmpty(this.companies)) {
        this.getCompanies();
      }
  
      if(!this.id){
        let languages = this.systemLanguages;
        let self = this;
  
        languages.forEach(item => {
          self.tempItem.translations.push({
            language_id: item.id,
            name: "",
          })
        })
  
        this.$store.commit(_MODULE_NAME + '/'+ SET_ITEM, self.tempItem);
      } else {
        let payload = {
          url: BASE_URL + '/' +this.id,
          id: this.id,
        }
        this.$store.dispatch(_MODULE_NAME + '/'+ GET_ITEM, payload)
      }
    },
    watch: {
      success(value) {
        if (value) {
          let message = this.id == null ? this.$t('general.successfully_created') : this.$t('general.successfully_updated');
          let self = this;
          setTimeout(() => {
            self.sweetAlertSuccess(message).then((result) => {
              if (result) {
                router.push({name: this.indexRouteName});
              }
            })
          }, 500);
        }
      }
    }
  }
  </script>
  
  <style scoped>
  #dashboard-box {
    padding: 10px;
  }
  </style>
  